import { Medium } from "@bkry/bowline-components/Medium";
import { Page } from "@bkry/bowline-components/Page";
import { getProjectAttributes } from "@bkry/bowline-redux/projects";
import { getEnv } from "@bkry/bowline-utils";
import { useSsrState } from "@issr/core";
import { sha256 } from "js-sha256";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  Row,
} from "reactstrap";
import Cookies from "universal-cookie";

const PasswordUnlockForm = ({ setUnlocked }, context) => {
  const REACT_APP_PROJECT_ID = getEnv("REACT_APP_PROJECT_ID");

  const [inputPassword, setInputPassword] = useSsrState("");
  const [showError, setShowError] = useSsrState(false);

  const cookieHandler = new Cookies();

  const projectData = useSelector((state) =>
    getProjectAttributes(state, REACT_APP_PROJECT_ID)
  );

  const setAccessKeyCookie = (value) => {
    setUnlocked(value);
    cookieHandler.set("access_key", value, {
      expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7), // 7 days
    });
  };

  const handlePasswordCheck = (e) => {
    e.preventDefault();
    if (inputPassword.length === 0) return;

    const isValid =
      sha256(inputPassword)?.toString() === projectData?.access_key;
    if (isValid) {
      setAccessKeyCookie(inputPassword);
    }

    setShowError(!isValid);
  };

  return (
    <Page id="password-unlock" title={context.t("Password Unlock")}>
      <Container className="py-5">
        <Row>
          <Col className="mx-auto" md={6}>
            {projectData?.logo_medium_original && (
              <div className="text-center mb-5 px-5">
                <Medium
                  mediumData={{
                    ...projectData?.logo_medium_original,
                    ...projectData?.logo_medium_metadata,
                  }}
                  altTitle={projectData?.title}
                  skipLazyLoading
                />
              </div>
            )}
            <p className="text-center">
              {context.t("This page is password protected.")}
            </p>
            <Form onSubmit={handlePasswordCheck}>
              <InputGroup>
                <Input
                  className="input"
                  type="password"
                  value={inputPassword}
                  onChange={(e) => setInputPassword(e.target.value)}
                  placeholder="Enter Password"
                />
                <Button color="secondary">{context.t("Check Password")}</Button>
              </InputGroup>
              {showError && (
                <p className="text-danger mx-auto w-50 mt-3 text-center">
                  {context.t("No Access")}
                </p>
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </Page>
  );
};

PasswordUnlockForm.propTypes = {
  setUnlocked: PropTypes.func.isRequired,
};

/**  define proptype for the 'translation' function  */
PasswordUnlockForm.contextTypes = {
  t: PropTypes.func,
};

export default PasswordUnlockForm;
